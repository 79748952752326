import http from "@/utils/request";

export function getList(data,id){
    return http({
        url:id?'/finance/commonApi.nd?id='+id:'/finance/commonApi.nd',
        method:'post',
        headers:{
            "Content-Type":'application/json',
        },
        type:'stringfy',
        data
    })
}
export function getDetail(data){
    return http({
        url:'/finance/commonApi.nd',
        method:'post',
        headers:{
            "Content-Type":'application/json',
        },
        type:'stringfy',
        data
    })
}
export function financeEditAndDetail(params){
    return http({
        url:'finance/financeEditAndDetail.nd',
        method:'get',
        headers:{
            "Content-Type":'application/json',
        },
        type:'stringfy',
        params
    })
}
export function getSellerInfo(data){
    return http({
        url:'/finance/getSellerInfo.nd',
        method:'post',
        headers:{
            "Content-Type":'application/json',
        },
        type:'stringfy',
        data
    })
}
export function saveFinance(data){
    return http({
        url:'/finance/commonApi.nd',
        method:'post',
        headers:{
            "Content-Type":'application/json',
        },
        type:'stringfy',
        data
    })
}