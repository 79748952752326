import {
  getDetail,
  financeEditAndDetail,
  getSellerInfo,
  saveFinance
} from "../api";

export default {
  data() {
    return {
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/finaceClaim",
          name: "finaceClaim",
          title: "资金认领"
        },
        {
          path: "/finaceClaim/detail",
          name: "finaceClaimeDtail",
          title: "资金认领详情"
        }
      ],
      columns: [
        {
          title: "办事处",
          dataIndex: "hifi_agency.name",
          key: "hifi_agency",
          width: "220px",
        },
        {
          title: "物料组",
          dataIndex: "hifi_e_product.name",
          key: "hifi_e_product",
          width: "150px",
        },
        {
          title: "信贷范围",
          dataIndex: "hifi_debeitrangeName",
          key: "hifi_debeitrange",
          width: "150px",
        },
        {
          title: "认领金额（元）",
          dataIndex: "e_actamt",
          key: "e_receivableamt",
          width: "150px",
        },
        {
          title: "业务员",
          dataIndex: "e_saleman.name",
          key: "e_saleman",
          width: "150px",
        }
      ],
      baseData: {
        baseData: [],
        financeData: [],
        maktlData: [],
        orgData: [],
        translateData: []
      },
      entryentity: [],
      detail: {},
      attachs: [],
      e_remark: "" //摘要
    };
  },
  created() {
    this.getData();
  },
  computed: {
    total() {
      return this.data.reduce((sum, item) => {
        return sum + (item.e_receivableamt || 0);
      }, 0);
    }
  },
  methods: {
    customRequest(options) {
      setTimeout(() => {
        options.onSuccess("success", options.file);
      }, 50);
    },
    fileChange({ fileList }) {
      this.attachs = [];
      for (let i = 0; i < fileList.length; i++) {
        let reader = new FileReader();
        reader.readAsDataURL(fileList[i].originFileObj);
        reader.onloadend = e => {
          // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
          let imgData = e.target.result;
          this.attachs.push({ name: fileList[i].name, content: imgData });
        };
      }
    },
    getData() {
      const data = {
        method: "getNoticeBill",
        params: { id: this.$route.query.id },
        loginType: "CS"
      };
      getDetail(data).then(res => {
        const item = res.data.data;
        item.hifi_flowstatusName =
          item.hifi_flowstatus == "account"
            ? "银行流水"
            : item.hifi_flowstatus == "draftbill"
            ? "票据流水"
            : item.hifi_flowstatus;
        const codeToName = {
          "0": "待认领",
          "1": "部分认领",
          "2": "已认领",
          "3": "已确认",
          "4": "申诉中",
          "5": "变更中"
        };
        item.claimstatusNmae = codeToName[item.claimstatus];
        this.detail = res.data.data;
        this.detail.entryentity.forEach(item=>{
          item.hifi_debeitrangeName = item.hifi_debeitrange=='N'?'正常机':item.hifi_debeitrange=='S'?'样机':item.hifi_debeitrange
        })
        this.entryentity = this.detail.entryentity||[]
        this.e_remark = this.detail.description;
      });
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
